import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css'; // cSpell: disable-line
import '@fontsource/roboto';
import '@fontsource/inconsolata';

import 'assets/css/theme.css';
import UserLayout from 'components/layout/UserLayout/UserLayout';
import { keycloakInstance } from 'utils/Helpers';
import useLogout from 'hooks/UseLogout';

const initializeKeycloak = async () => {
  const authenticated = await keycloakInstance.init({
    onLoad: 'check-sso',
    checkLoginIframe: false,
  });

  if (!authenticated) {
    await keycloakInstance.login();
  }
};

function App() {
  const [userInfoLoaded, setUserInfoLoaded] = React.useState(false);
  const logout = useLogout();
  const { authenticated } = keycloakInstance;

  React.useEffect(() => {
    if (!authenticated) {
      initializeKeycloak().catch(() => {});
    }
  }, [authenticated]);

  keycloakInstance.onReady = () => {
    keycloakInstance.loadUserInfo().then((userInfo) => {
      setUserInfoLoaded(userInfo !== undefined);
    }).catch(() => {});
  };

  keycloakInstance.onAuthLogout = () => logout();
  keycloakInstance.onTokenExpired = () => logout();

  // Wait for token to load to prevent resetting path
  return (!authenticated || !userInfoLoaded) ? <div className="carbon-fiber-background vh-100 vw-100" /> : <UserLayout />;
}

export default App;
