import {
  useQuery,
  useQueries,
  useQueryClient,
  type QueryClient,
} from '@tanstack/react-query';

import type {
  AgGridUser,
  AgGridUserPatch,
  INewUserPost,
  IUser,
  IUserDetails,
  IUserProfile,
  UserSetting,
  UserSettings,
  UserSettingsField,
} from 'types/IUser';
import {
  authHttpGet,
  authHttpPatch,
  authHttpPost,
  userInfo,
} from 'utils/Helpers';

/**
 *
 * @returns
 */
const organizationUsersUrl = () => `/data/v1/organizations/${userInfo()?.orgId}/users`;

/**
 *
 */
const getUsersQuery = {
  queryKey: ['GetUsers'],
  queryFn: () => authHttpGet<IUserDetails[]>(organizationUsersUrl()),
};

/**
 *
 * @returns
 */
const useGetUsers = () => useQuery(getUsersQuery);

/**
 *
 */
const getUserProfilesQuery = {
  queryKey: ['GetUsersProfiles'],
  queryFn: () => (authHttpGet<IUserProfile[]>('/auth/users')),
};

/**
 *
 * @param enabled
 * @returns
 */
const useGetUserProfiles = (enabled: boolean = true) => useQuery({
  ...getUserProfilesQuery,
  enabled,
});

/**
 *
 * @param enabled
 * @returns
 */
const useGetSelfProfile = (enabled: boolean = true) => useQuery({
  queryKey: ['GetSelfProfile'],
  queryFn: () => (authHttpGet<IUserProfile>('/auth/users/self')),
  enabled,
});

const useGetUsersAndProfiles = () => (
  useQueries({
    queries: [
      getUsersQuery,
      getUserProfilesQuery,
    ],
  })
);

/**
 *
 * @returns
 */
const useGetUserSettings = () => useQuery({
  queryKey: ['GetUserSettings'],
  queryFn: () => authHttpGet<UserSettings>(
    `/auth/users/${userInfo()?.userId}/settings`,
  ),
});

/**
 *
 * @param queryClient
 */
const resetProfileQueries = (queryClient: QueryClient) => {
  queryClient.invalidateQueries({ queryKey: ['GetUsersProfiles'] }).catch(() => {});
  queryClient.invalidateQueries({ queryKey: ['GetSelfProfile'] }).catch(() => {});
};

/**
 *
 * @returns
 */
const usePatchUserProfile = () => {
  const queryClient = useQueryClient();

  return ({ userId, ...patchData }: AgGridUserPatch & { userId: string }) => (
    authHttpPatch(
      `/auth/users/${userId}`,
      patchData,
    )
  )
    .then(() => resetProfileQueries(queryClient))
    .catch(() => resetProfileQueries(queryClient));
};

/**
 *
 * @returns
 */
const usePatchUserData = () => {
  const queryClient = useQueryClient();

  return ({ userId, ...userPatch }: AgGridUserPatch & { userId: string }) => authHttpPatch(
    `${organizationUsersUrl()}/${userId}`,
    userPatch,
  )
    .then(() => queryClient.invalidateQueries({ queryKey: ['GetUsers'] }))
    .catch(() => {});
};

/**
 *
 * @returns
 */
const usePatchUser = () => {
  const patchUserProfile = usePatchUserProfile();
  const patchUserData = usePatchUserData();

  return (user: AgGridUser) => {
    const {
      userId, firstName, lastName, role, orgId, active, isAdmin,
    } = user;

    const patchData: AgGridUserPatch & { userId: string } = {
      firstName,
      lastName,
      role,
      orgId,
      active,
      userId,
      ...isAdmin && { isAdmin },
    };
    return Promise.all([
      patchUserProfile(patchData),
      patchUserData(patchData),
    ]);
  };
};

/**
 *
 * @returns
 */
const usePatchSelfProfile = () => {
  const queryClient = useQueryClient();

  const userToken = userInfo();

  return (userPatch: Partial<IUser>) => (
    authHttpPatch(`/auth/users/${userToken?.userId}`, userPatch)
      .then(() => resetProfileQueries(queryClient))
      .catch(() => resetProfileQueries(queryClient))
  );
};

/**
 * PATCH a single user setting
 */
const usePatchUserSetting = () => {
  const queryClient = useQueryClient();
  const userToken = userInfo();

  // const queryClient = useQueryClient();

  type SettingUpdate<F extends UserSettingsField = UserSettingsField> = {
    field: F;
    value: UserSetting<F>;
  };

  return ({ field, value }: SettingUpdate) => (
    authHttpPatch(
      `/auth/users/${userToken?.userId}/settings`,
      [{
        op: 'add',
        path: `/${field}`,
        value,
      }],
    )
  )
    .then(() => queryClient.invalidateQueries({ queryKey: ['GetUserSettings'] }))
    .catch(() => {});
};

/**
 * PATCH multiple user settings
 */
const usePatchUserSettings = () => {
  const userToken = userInfo();

  type SettingUpdate<F extends UserSettingsField = UserSettingsField> = {
    field: F;
    value: UserSetting<F>;
  };

  return (settings: SettingUpdate[]) => (
    authHttpPatch(
      `/auth/users/${userToken?.userId}/settings`,
      settings.map((setting) => ({
        op: 'add',
        path: `/${setting.field}`,
        value: setting.value,
      })),
    )
  )
    .catch(() => {});
};

/**
 *
 * @returns
 */
const usePostUser = () => {
  const queryClient = useQueryClient();

  return (userPost: INewUserPost) => (
    authHttpPost<Record<string, string>>('/auth/users/', userPost)
  ).then(async (response) => {
    const { userId } = response;
    await authHttpPost(organizationUsersUrl(), { id: userId });
    return Promise.all([
      queryClient.invalidateQueries({ queryKey: ['GetUsers'] }),
      queryClient.invalidateQueries({ queryKey: ['GetUsersProfiles'] }),
    ]);
  });
};

export {
  useGetUsers,
  useGetUserProfiles,
  useGetSelfProfile,
  useGetUsersAndProfiles,
  useGetUserSettings,

  usePatchUser,
  usePatchSelfProfile,
  usePatchUserSetting,
  usePatchUserSettings,

  usePostUser,
};
