import React from 'react';
import ReactDatePicker from 'react-datepicker';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from 'reactstrap';
import { type ICellRendererParams } from '@ag-grid-community/core';
import { MdEdit } from 'react-icons/md';

import { usePatchRequiredMx } from 'apiHooks/Vehicles.Hook';
import type { IRequiredMx, IRequiredMxPatch } from 'types/Mx';
import { AF1800_STATUS_CODE_LABELS, AF1800_STATUS_CODES } from 'utils/Constants';
import { RhinoModalHeader } from 'components/shared/RhinoModal/RhinoModal';
import { isEqual } from 'date-fns';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useGetUsers } from 'apiHooks/Users.Hook';
import type { IUserDetails } from 'types/IUser';

type PatchDataNoDates = Partial<Omit<IRequiredMxPatch, 'workDate' | 'reportDate' | 'pictures'>>;

function EditRequiredMxModal({ requiredMx }: { requiredMx: IRequiredMx; }) {
  const origReportDate = requiredMx.reportDate ? new Date(requiredMx.reportDate) : null;
  const origWorkDate = requiredMx.workDate ? new Date(requiredMx.workDate) : null;
  const [modalOpen, setModalOpen] = React.useState(false);
  const toggleModalOpen = () => setModalOpen((b) => !b);
  const [patchDataNoDates, setPatchDataNoDates] = React.useState<PatchDataNoDates>({});
  const [reportDate, setReportDate] = React.useState<Date | null>(origReportDate);
  const [workDate, setWorkDate] = React.useState<Date | null>(origWorkDate);

  const {
    data: users,
  } = useGetUsers();

  const reporterUser = users?.filter(
    (user) => user.userId === requiredMx.reporterUserId,
  ).slice(0, 1);

  const resetStateAndClose = () => {
    setReportDate(origReportDate);
    setWorkDate(origWorkDate);
    setPatchDataNoDates({});
    setModalOpen(false);
  };

  const patchRequiredMx = usePatchRequiredMx(requiredMx.regNumber, requiredMx.mxId);

  const onInputChange = <K extends keyof PatchDataNoDates>(
    name: K,
    value: IRequiredMx[K],
  ) => {
    if ((value === '' || value === null || Number.isNaN(value))) {
      const { [name]: unusedParam, ...newPatchData } = patchDataNoDates;
      setPatchDataNoDates(newPatchData);
    } else {
      setPatchDataNoDates((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const canSubmit = () => (
    Object.keys(patchDataNoDates).length > 0
    || !isEqual(reportDate ?? 0, origReportDate ?? 1)
    || !isEqual(workDate ?? 0, origWorkDate ?? 1)
  );

  const handleSubmit = () => {
    if (!canSubmit()) {
      return;
    }

    const workDateStr = workDate?.toISOString() ?? null;
    const reportDateStr = reportDate?.toISOString() ?? null;

    const patchData: IRequiredMxPatch = {
      ...patchDataNoDates,
      ...(workDate === origWorkDate || workDate === null ? {} : (
        { workDate: workDateStr }
      )),
      ...(reportDate === origReportDate || reportDateStr === null ? {} : (
        { reportDate: reportDateStr }
      )),
    };

    patchRequiredMx(patchData)
      .then(() => setModalOpen(false))
      .catch(() => {});
  };

  return (
    <>
      <Button color="link" onClick={() => setModalOpen(true)}>
        <MdEdit />
      </Button>

      <Modal
        centered
        isOpen={modalOpen}
        toggle={resetStateAndClose}
      >
        <RhinoModalHeader toggle={toggleModalOpen}>
          {`Edit Required Mx for ${requiredMx.regNumber}`}
        </RhinoModalHeader>

        <ModalBody className="d-flex flex-column gap-2 text-secondary fw-bold">
          <div className="d-flex align-items-center justify-content-end">
            <span className="text-nowrap text-end col-6 px-1">Date Reported:</span>
            <ReactDatePicker
              showIcon
              placeholderText={requiredMx.reportDate ? (
                new Date(requiredMx.reportDate)?.toLocaleDateString('en-US')
              ) : undefined}
              selected={reportDate}
              onChange={setReportDate}
              className="py-1"
            />
          </div>

          <div className="d-flex align-items-center">
            <span className="text-nowrap text-end col px-1">Report Miles:</span>
            <Input
              className="col"
              onChange={(e) => onInputChange('reportMiles', Number.parseInt(e.target.value, 10))}
              defaultValue={requiredMx.reportMiles ?? undefined}
            />
          </div>

          <div className="d-flex align-items-center">
            <span className="text-nowrap text-end col px-1">Report Hours:</span>
            <Input
              className="col"
              onChange={(e) => onInputChange('reportHours', Number.parseInt(e.target.value, 10))}
              defaultValue={requiredMx.reportHours ?? undefined}
            />
          </div>

          <div className="d-flex align-items-center">
            <span className="text-nowrap text-end col px-1">Reporter:</span>
            {/* <Input
              className="col"
              onChange={(e) => onInputChange('reporterUserId', Number.parseInt(e.target.value, 10))}
              defaultValue={requiredMx.reporterUserId ?? undefined}
            /> */}

            <Typeahead
              id="reporter-typeahead"
              isLoading={!users}
              className="col"
              options={users ?? []}
              defaultSelected={reporterUser ?? []}
              labelKey={(u) => {
                const user = u as IUserDetails;
                return `${user.firstName} ${user.lastName}`;
              }}
              onChange={(selected) => onInputChange(
                'reporterUserId',
                ((selected as IUserDetails[])?.[0])?.userId ?? null,
              )}
            />
          </div>

          <div className="d-flex align-items-center">
            <span className="text-nowrap text-end col px-1">Work Order:</span>
            <Input
              maxLength={15}
              className="col"
              onChange={(e) => onInputChange('workOrder', e.target.value)}
              defaultValue={requiredMx.workOrder ?? undefined}
            />
          </div>

          <div className="d-flex align-items-center justify-content-end">
            <span className="text-nowrap text-end col-6 px-1">Last Updated by Mx:</span>
            <ReactDatePicker
              showIcon
              placeholderText={requiredMx.workDate ? (
                new Date(requiredMx.workDate)?.toLocaleDateString('en-US')
              ) : undefined}
              selected={workDate}
              onChange={setWorkDate}
              className="py-1"
            />
          </div>

          <div className="d-flex align-items-center">
            <span className="text-nowrap text-end col px-1">AF-1800 Status Code:</span>
            <Input
              type="select"
              className="col"
              onChange={(e) => onInputChange('af1800StatusCode', Number.parseInt(e.target.value, 10))}
              defaultValue={requiredMx.af1800StatusCode ?? undefined}
            >
              <option value="" disabled />
              {
                AF1800_STATUS_CODES.map((statusCode) => (
                  <option
                    key={statusCode}
                    value={statusCode}
                  >
                    {AF1800_STATUS_CODE_LABELS[statusCode] || statusCode}
                  </option>
                ))
              }
            </Input>
          </div>

          <div className="d-flex align-items-center">
            <span className="text-nowrap text-end col px-1">Mx Employee:</span>
            <Input
              className="col"
              onChange={(e) => onInputChange('mxUserId', e.target.value)}
              defaultValue={requiredMx.mxUserId ?? undefined}
            />
          </div>

          <div className="d-flex align-items-center">
            <span className="text-nowrap text-end col px-1">System Code:</span>
            <Input
              maxLength={10}
              className="col"
              onChange={(e) => onInputChange('systemCode', e.target.value)}
              defaultValue={requiredMx.systemCode ?? undefined}
            />
          </div>

          <div className="d-flex align-items-center">
            <span className="text-nowrap text-end col px-1">Number of Items Fixed:</span>
            <Input
              className="col"
              onChange={(e) => onInputChange('numFixed', Number.parseInt(e.target.value, 10))}
              defaultValue={requiredMx.numFixed ?? undefined}
            />
          </div>

          <div className="d-flex align-items-center justify-content-center">
            <Label>
              <Input
                type="checkbox"
                defaultChecked={requiredMx.disregard}
                onChange={(e) => onInputChange('disregard', e.target.checked)}
              />
              <span className="text-nowrap text-end px-1">Disregard</span>
            </Label>
          </div>
        </ModalBody>

        <ModalFooter className="d-flex justify-content-between">
          <Button onClick={resetStateAndClose}>Cancel</Button>
          <Button disabled={!canSubmit()} onClick={handleSubmit}>Update</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

function AgGridEditRequiredMxCellRenderer({ data } : ICellRendererParams) {
  return <EditRequiredMxModal requiredMx={data as IRequiredMx} />;
}

export default AgGridEditRequiredMxCellRenderer;
