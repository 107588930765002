import useLogout from 'hooks/UseLogout';
import React from 'react';
import { Button } from 'reactstrap';
import { userInfo } from 'utils/Helpers';
import { useGetOrganizationName } from 'apiHooks/Organization.Hook';
import DownloadPwaButton from 'components/shared/DownloadPwaButton';
import styles from './InspectorLayout.module.css';

function InspectorLayout() {
  const logOut = useLogout();
  const { givenName, fullName } = userInfo() || {};
  const { data: orgName } = useGetOrganizationName();

  return (
    <div className="d-flex flex-column min-vh-100 gap-5 align-items-center justify-content-center bg-secondary text-light">

      <div className="d-flex flex-column text-center align-items-center justify-content-center">
        <h1>{`Welcome to RHINO, ${givenName}`}</h1>
        As an Inspector, you do not have access to the RHINO website.
        <br />
        If necessary, contact an administrator to change your role.
      </div>

      <DownloadPwaButton />

      <div className="d-flex flex-column align-items-center justify-content-center gap-2">
        <h2>Your Profile</h2>
        {fullName}
        <table className={styles['profile-table']}>
          <tbody>
            <tr>
              <td>Organization</td>
              <td>{orgName?.orgName || ''}</td>
            </tr>
            <tr>
              <td>Role</td>
              <td>Inspector</td>
            </tr>
          </tbody>
        </table>
      </div>

      <Button
        onClick={logOut}
        className="border border-light"
      >
        Log Out
      </Button>

    </div>
  );
}

export default InspectorLayout;
