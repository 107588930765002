import { useGetOrganizationShiftTimes, usePutOrganizationShiftTimes } from 'apiHooks/Organization.Hook';
import React, { useEffect, useRef, useState } from 'react';
import type { TypeaheadRef } from 'react-bootstrap-typeahead';
import { Typeahead } from 'react-bootstrap-typeahead';
import ReactDatePicker from 'react-datepicker';
import {
  Button,
  Table,
} from 'reactstrap';
import type { IShift, IShiftDay } from 'types/IOrganization';
import { signOf, formatTime } from 'utils/Helpers';
import RhinoModal from 'components/shared/RhinoModal/RhinoModal';
import { memoize } from 'lodash-es';
import Loading from 'components/shared/Loading/Loading';

export const DAYS_OF_THE_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
] as const;

const parseShiftTimeAsDate = (shiftTime: string | undefined) => (
  shiftTime ? new Date(`${new Date().toISOString().slice(0, 10)}T${shiftTime.slice(0, 2)}:${shiftTime.slice(2)}`) : null
);

const parseDateAsShiftTime = (date: Date) => {
  const time = formatTime(date);
  // console.log(time);
  return `${time.slice(0, 2)}${time.slice(3)}Z`;
};

const signedNumber = (n: number) => `${signOf(n)}${Math.abs(n)}`;

const formatTimeZone = memoize((timeZone: string) => {
  const now = new Date();
  const timeZoneHours = Number.parseInt(
    now.toLocaleTimeString(
      'en-US',
      {
        hour: '2-digit',
        hourCycle: 'h23',
        timeZone,
      },
    ),
    10,
  );
  const utcHours = now.getUTCHours();
  const timeDiff = timeZoneHours - utcHours;
  return `${timeZone.replaceAll('/', ' / ').replaceAll('_', ' ')} (UTC ${signedNumber(timeDiff)})`;
});

function SetSingleShiftModal({
  shifts, dayIndex, shiftIndex, setShift, timeZone,
}: {
  shifts: IShiftDay[];
  dayIndex: number,
  shiftIndex: number,
  setShift: (start: Date, end: Date) => void,
  timeZone: string,
}) {
  const shiftDay = shifts[dayIndex];
  const shift = shiftDay?.shifts[shiftIndex];

  const shiftStartDate = parseShiftTimeAsDate(shift?.start);
  const shiftEndDate = parseShiftTimeAsDate(shift?.end);

  const [modalOpen, setModalOpen] = useState(false);
  const [startTime, setStartTime] = useState<Date | null>(shiftStartDate);
  const [endTime, setEndTime] = useState<Date | null>(shiftEndDate);

  const copyStateFromShifts = () => {
    setStartTime(shiftStartDate);
    setEndTime(shiftEndDate);
  };

  const toggleModalOpen = () => setModalOpen((state) => {
    if (!state) { // opening modal
      copyStateFromShifts();
    }
    return !state;
  });

  const canSetShifts = !!(startTime && endTime);

  const onSubmit = () => Promise.resolve(
    canSetShifts
      ? setShift(startTime, endTime)
      : undefined,
  );

  if (!shift) return null;

  return (
    <>
      <Button onClick={toggleModalOpen}>
        {
          `${formatTime(shiftStartDate, { timeZone })
          } - ${formatTime(shiftEndDate, { timeZone })
          }`
        }
      </Button>
      <RhinoModal
        title={`Set times for shift ${shiftIndex + 1} on ${DAYS_OF_THE_WEEK[dayIndex]}`}
        centered
        isOpen={modalOpen}
        setIsOpen={toggleModalOpen}
        canSubmit={canSetShifts}
        onSubmit={onSubmit}
        submitButtonText="Set Shift"
      >
        <div className="d-flex gap-3">
          <div className="d-grid">
            Start Time
            <ReactDatePicker
              showIcon
              showTimeSelect
              showTimeSelectOnly
              timeFormat="HH:mm"
              selected={startTime}
              onChange={(date) => setStartTime(date)}
              value={startTime ? formatTime(startTime, { timeZone }) : 'unset'}
            />
          </div>
          <div className="d-grid">
            End Time
            <ReactDatePicker
              showIcon
              showTimeSelect
              showTimeSelectOnly
              timeFormat="HH:mm"
              selected={endTime}
              onChange={(date) => setEndTime(date)}
              value={endTime ? formatTime(endTime, { timeZone }) : 'unset'}
            />
          </div>
        </div>
      </RhinoModal>
    </>
  );
}

function SetShiftEveryDayModal({
  shiftIndex, setShiftEveryDay, timeZone,
}: {
  shiftIndex: number,
  timeZone: string,
  setShiftEveryDay: (start: Date, end: Date) => void,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModalOpen = () => setModalOpen((s) => !s);

  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);

  const canSetShifts = !!(startTime && endTime);

  const onSubmit = () => Promise.resolve(
    canSetShifts
      ? setShiftEveryDay(startTime, endTime)
      : undefined,
  );

  return (
    <>
      <Button onClick={toggleModalOpen}>
        Edit All
      </Button>
      <RhinoModal
        title={`Set times for shift ${shiftIndex + 1} every day`}
        centered
        isOpen={modalOpen}
        setIsOpen={toggleModalOpen}
        canSubmit={canSetShifts}
        onSubmit={onSubmit}
        submitButtonText="Set Shift"
      >
        <div className="d-flex gap-3">
          <div className="d-grid">
            Start Time
            <ReactDatePicker
              showIcon
              showTimeSelect
              showTimeSelectOnly
              timeFormat="HH:mm"
              selected={startTime}
              onChange={(date) => setStartTime(date)}
              value={startTime ? formatTime(startTime, { timeZone }) : 'unset'}
            />
          </div>
          <div className="d-grid">
            End Time
            <ReactDatePicker
              showIcon
              showTimeSelect
              showTimeSelectOnly
              timeFormat="HH:mm"
              selected={endTime}
              onChange={(date) => setEndTime(date)}
              value={endTime ? formatTime(endTime, { timeZone }) : 'unset'}
            />
          </div>
        </div>
      </RhinoModal>
    </>
  );
}

export default function ShiftTimesModal() {
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModalOpen = () => setModalOpen((s) => !s);
  // init to the time zone of the locale
  const [selectedTimeZone, setSelectedTimeZone] = useState<[string]>(
    [Intl.DateTimeFormat().resolvedOptions().timeZone],
  );

  const timeZone = selectedTimeZone[0];

  const typeaheadRef = useRef<TypeaheadRef>(null);

  const [shifts, setShifts] = useState<IShiftDay[]>();

  const {
    data: shiftResponse,
    isFetching,
    isError,
    error,
  } = useGetOrganizationShiftTimes(modalOpen);

  const putOrganizationShiftTimes = usePutOrganizationShiftTimes();

  useEffect(() => {
    if (!shiftResponse) return;
    // deep copy so that shiftResponse and shifts can be meaningfully compared.
    setShifts(structuredClone(shiftResponse));
  }, [shiftResponse]);

  // Sets *only* shift ${shiftIndex + 1} on day ${dayIndex}.
  const setShift = (dayIndex: number, shiftIndex: number) => (
    (start: Date, end: Date) => {
      setShifts((currShifts) => {
        if (!currShifts) return undefined;

        const thisDay = currShifts[dayIndex];
        const thisShift = thisDay?.shifts[shiftIndex];
        if (!thisShift) return undefined;

        if (start) thisShift.start = parseDateAsShiftTime(start);
        if (end) thisShift.end = parseDateAsShiftTime(end);

        const updatedShifts: IShiftDay[] = [
          ...currShifts.slice(0, dayIndex),
          {
            ...thisDay,
            shifts: [
              ...currShifts[dayIndex]?.shifts.slice(0, shiftIndex) ?? [],
              thisShift,
              ...currShifts[dayIndex]?.shifts.slice(shiftIndex + 1) ?? [],
            ],
          },
          ...currShifts.slice(dayIndex + 1),
        ];
        return updatedShifts;
      });
    }
  );

  const setShiftEveryDay = (shiftIndex: number) => (
    (start: Date, end: Date) => {
      setShifts((currShifts) => {
        if (!currShifts) return undefined;

        const newShift: IShift = {
          number: shiftIndex + 1,
          start: parseDateAsShiftTime(start),
          end: parseDateAsShiftTime(end),
        };

        const updatedShifts: IShiftDay[] = currShifts.map((shiftDay) => ({
          ...shiftDay,
          shifts: [
            ...shiftDay.shifts.slice(0, shiftIndex),
            { ...newShift },
            ...shiftDay.shifts.slice(shiftIndex + 1),
          ],
        }));

        return updatedShifts;
      });
    }
  );

  const canSubmit = () => {
    for (let i = 0; i < 7; i += 1) {
      for (let j = 0; j < 3; j += 1) {
        const currShift = shiftResponse?.[i]?.shifts[j];
        const newShift = shifts?.[i]?.shifts[j];
        if (
          currShift?.start !== newShift?.start
          || currShift?.end !== newShift?.end
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const handleSubmit = shifts
    ? () => putOrganizationShiftTimes({
      tzOffset: 0, // -(new Date().getTimezoneOffset()) / 60,
      shifts,
    })
    : undefined;

  if (isError) return <span>{error as string}</span>;

  return (
    <>
      <Button
        className="border"
        onClick={toggleModalOpen}
      >
        Shift Times
      </Button>

      <RhinoModal
        // title={`Shift Times for ${userOrganizationName()}`}
        title="Shift Times"
        size="xl"
        isOpen={modalOpen}
        setIsOpen={setModalOpen}
        canSubmit={canSubmit()}
        onSubmit={handleSubmit}
      >

        {(isFetching)
          ? (
            <Loading />
          ) : (
            <>
              <Table>
                <thead>
                  <tr>
                    <th>
                      {/* edit all */}
                    </th>
                    <th>
                      {/* Shift number */}
                    </th>
                    {shifts?.map((shiftDay) => (
                      <th key={shiftDay.day}>{shiftDay.day}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {[1, 2, 3].map((shiftNumber, shiftIndex) => (
                    <tr key={shiftNumber}>
                      <td>
                        <SetShiftEveryDayModal
                          shiftIndex={shiftIndex}
                          timeZone={timeZone}
                          setShiftEveryDay={setShiftEveryDay(shiftIndex)}
                        />
                      </td>
                      <td className="fw-bold">{`Shift ${shiftNumber}`}</td>
                      {shifts?.map((shiftDay, dayIndex) => (
                        <td key={shiftDay.day}>
                          <SetSingleShiftModal
                            shifts={shifts}
                            dayIndex={dayIndex}
                            shiftIndex={shiftIndex}
                            setShift={setShift(dayIndex, shiftIndex)}
                            timeZone={timeZone}
                          />
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>

              <div className="d-flex flex-column gap-1 align-items-start">
                <span className="px-2">
                  Time Zone:
                  {' '}
                  <span className="fw-bold">
                    {formatTimeZone(timeZone)}
                  </span>

                </span>
                <Typeahead
                  ref={typeaheadRef}
                  className="w-50"
                  id="time-zone-typeahead"
                  placeholder="Search time zones"
                  options={Intl.supportedValuesOf('timeZone')}
                  labelKey={(option) => formatTimeZone(option as string) ?? option as string}
                  onChange={(selected) => {
                    if (selected[0]) {
                      setSelectedTimeZone(selected as [string]);
                      typeaheadRef.current?.clear();
                      typeaheadRef.current?.blur();
                    }
                  }}
                  onBlur={() => typeaheadRef.current?.hideMenu()}
                />
              </div>

              {/* <div className="d-flex">
                <pre>{JSON.stringify(shifts, null, '  ')}</pre>
                <pre>{JSON.stringify(shiftResponse, null, '  ')}</pre>
              </div> */}

            </>
          )}
      </RhinoModal>
    </>
  );
}
