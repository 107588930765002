import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'jotai';

import { SITE_TITLE } from 'utils/Constants';
import App from './App';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      const { status, url } = error as Response;
      if (status === 403 || (status === 404 && url.includes('refresh'))) {
        queryClient.clear();
        window.location.href = '/';
        // jotaiStore = createStore();
        queryClient.clear();
      }
    },
  }),
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        const { status, url } = error as Response;
        return (
          status !== 401
          && status !== 403
          && !(status === 404 && url.includes('refresh')));
      },
    },
  },
});
const rootElement = document.querySelector('#root');

document.title = SITE_TITLE;

if (rootElement) {
  createRoot(rootElement).render(
    <React.StrictMode>
      <Provider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <App />
          </Router>
        </QueryClientProvider>
      </Provider>
    </React.StrictMode>,
  );
}
