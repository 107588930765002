import React from 'react';
import { useGetUsers } from 'apiHooks/Users.Hook';
import { formatDate } from 'utils/Helpers';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { MdClose } from 'react-icons/md';
import { useDeleteRhinoEvent } from './RhinoEvent.store';

function RhinoEvent({ event }: { event: CompletionEvent; }) {
  const {
    eventId,
    eventType,
    eventTime,
    regNumber,
    vehicleType,
    userId,
  } = event;

  const deleteRhinoEvent = useDeleteRhinoEvent();

  const { data: users } = useGetUsers();
  const user = users?.find((u) => u.userId === userId);

  let eventDetails: {
    title: string;
    description: string;
  } | undefined;

  switch (eventType) {
    case 'inspection': {
      const { discrepancies } = event;
      const isPassing = discrepancies === 0;
      eventDetails = {
        title: `${isPassing ? 'Passed' : 'Failed'} Inspection`,
        description: `With ${discrepancies} new discrepanc${discrepancies > 1 ? 'ies' : 'y'}.`,
      };
      break;
    }
    case 'discrepancy': {
      const { discrepancy, disregard, estCompletionDate } = event;
      const descDetails = [
        discrepancy,
        disregard && 'may disregard',
        estCompletionDate && `\u2013 Est. completion: ${formatDate(estCompletionDate, false)}`,
      ].filter(Boolean);
      eventDetails = {
        title: 'Discrepancy',
        description: descDetails.join(' \u2013 '),
      };
      break;
    }
    case 'status': {
      const { status } = event;
      eventDetails = {
        title: 'Status',
        description: `Vehicle is now ${status}`,
      };
      break;
    }
    case 'note': {
      const { note } = event;
      eventDetails = {
        title: 'Note',
        description: note,
      };
      break;
    }
    case 'mx': {
      const { mxType } = event;
      eventDetails = {
        title: 'Scheduled MX Completed',
        description: mxType,
      };
      break;
    }
    case 'lti': {
      const { passed, notes } = event;
      const descDetails = [
        passed ? 'Passed' : 'Did not pass',
        notes && `Notes: ${notes}`,
      ].filter(Boolean);
      eventDetails = {
        title: 'LTI Completed',
        description: descDetails.join(' \u2013 '),
      };
      break;
    }
    case 'pmi': {
      const { passed, notes } = event;
      const descDetails = [
        passed ? 'Passed' : 'Did not pass',
        notes && `Notes: ${notes}`,
      ].filter(Boolean);
      eventDetails = {
        title: 'PMI Completed',
        description: descDetails.join(' \u2013 '),
      };
      break;
    }
    default: {
      break;
    }
  }

  if (!eventDetails) return null;

  return (
    <div className="d-flex flex-row justify-content-between p-2 border shadow rounded bg-white">
      <div className="d-flex flex-row gap-4">

        <div style={{ width: '50%' }} className="flex-fill d-flex flex-column align-items-end">
          <div className="fw-bold mb-1">
            <Link to={`/Vehicle/${regNumber}`} className="text-primary">
              {`${vehicleType} \u2013 ${regNumber}`}
            </Link>
          </div>
          <div className="small">
            {formatDate(eventTime)}
          </div>
          <div className="small">
            {`${user?.firstName} ${user?.lastName}`}
          </div>
        </div>

        <div>
          <div className="fw-bold">
            {eventDetails.title}
          </div>
          {eventDetails.description}
        </div>

      </div>

      <Button
        className="align-self-center rounded-5"
        onClick={() => deleteRhinoEvent(eventId)}
      >
        <MdClose />
      </Button>

    </div>

  );
}

export default RhinoEvent;
